import { FormManager } from '@visto-tech/forms';
import { formatDistance } from 'date-fns';
import { detect } from 'detect-browser';
import {
  Account as AccountRaw,
  Application,
  ApplicationType,
  CompanyTalent,
  CompanyTalent as RawCompanyTalent,
  Feedback,
  FeedbackStatus,
  FormSubmission,
  Person as RawPerson,
  Role,
  Status as RawStatus,
  StatusType,
  StatusValue,
  User as UserRaw,
} from 'generated/graphql';
import _, { get, isEmpty } from 'lodash';
import lodash, { head, trim } from 'lodash';
import router, { NextRouter } from 'next/router';
import { FileRejection } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { fileSizeLimitString } from 'static/ApplicationDocuments';
import {
  ApplicationProgressStatusLabels,
  STATUSES_GTS,
  STATUSES_WORK_PERMIT,
} from 'static/ApplicationStatuses';

import logger from './logger';

type GetRolesAndTalentFromApplicationType = {
  role: Role;
  talents: CompanyTalent[];
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const PASSWORD_NO_MATCH_ERROR_MESSAGE = 'Passwords do not match';
export const PASSWORD_STRENGTH_ERROR_MESSAGE =
  'Password is not strong enough, please make sure your password has at least: 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character and 8 characters long';

export const formatDate = (
  dateTime: Date | string,
  hide?: {
    weekday?: boolean;
    year?: boolean;
    month?: boolean;
    day?: boolean;
    hour?: boolean;
    minute?: boolean;
    second?: boolean;
  },
  timeZone?: 'UTC'
) => {
  if (!dateTime) return null;

  if (hide && hide?.hour === undefined) hide.hour = true;

  if (hide && hide?.minute === undefined) hide.minute = true;

  if (hide && hide?.second === undefined) hide.second = true;

  const options: Intl.DateTimeFormatOptions = {
    weekday: !hide?.weekday ? 'long' : undefined,
    year: !hide?.year ? 'numeric' : undefined,
    month: !hide?.month ? 'long' : undefined,
    day: !hide?.day ? 'numeric' : undefined,
    hour: !hide?.hour ? 'numeric' : undefined,
    minute: !hide?.minute ? 'numeric' : undefined,
    second: !hide?.second ? 'numeric' : undefined,
  };

  if (timeZone) {
    options.timeZone = timeZone;
  }

  const newDate = new Date(dateTime);

  const isShortDate = isValidShortDate(dateTime);

  const date = new Date(
    newDate.getTime() + newDate.getTimezoneOffset() * (isShortDate ? 60000 : 1) // timezone offset if date is formatted as YYYY-MM-DD
  );

  return date.toLocaleDateString('en-US', options);
};

export const isValidShortDate = (date: Date | string) => {
  // Checks if date matches YYYY-MM-DD
  const regEx = /^\d{4}-\d{2}-\d{2}$/;

  if (typeof date === 'string' && !date.match(regEx)) return false;

  const d = new Date(date);
  const dNum = d.getTime();

  if (!dNum && dNum !== 0) return false;

  return d.toISOString().slice(0, 10) === date;
};

export const formatDateForForms = (date: Date | null) => {
  if (!date) return '';

  return date
    .toLocaleString('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
};

export const dateDiffInDays = (
  startDate?: Date | null,
  endDate?: Date | null
) => {
  const dateA = startDate ? new Date(startDate) : new Date();
  const dateB = endDate ? new Date(endDate) : new Date();

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
  const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());

  return Math.floor((utc1 - utc2) / _MS_PER_DAY);
};

export const dateBreakdownByDays = (numberOfDays: number) => {
  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  return { years, months, days };
};

export const getApplicantFromTalent = async (
  companyTalent: RawCompanyTalent
) => {
  return head(
    companyTalent.talentAccount?.persons?.filter(
      (person: RawPerson | null) => person?.type === 'APPLICANT'
    )
  );
};

export const getUserFromTalent = (talent: CompanyTalent) => {
  return head(talent?.talentAccount?.users) ?? null;
};

export const getRolesFromTalent = (talent: any) =>
  _.chain(talent)
    .groupBy((app) => app?.role?.id)
    .map((talents: CompanyTalent[]) => {
      const [talent] = talents;

      return {
        title: talent.role?.title,
        id: talent.role?.id,
      };
    })
    .value();

export const changeUrlParam = (
  queryKey: string,
  queryValue: string | number,
  pathname?: string,
  shallow = true
) => {
  router.push(
    {
      pathname: pathname ?? window.location.pathname,
      query: { [queryKey]: queryValue },
    },
    undefined,
    { shallow }
  );
};

export const getApplicationProgressDetails = (
  statuses: ApplicationProgressStatusLabels[],
  applicationStatus: RawStatus
) => {
  const currentStatus = statuses.find((status) =>
    status.values.includes(applicationStatus.value)
  );
  const stepIndex = statuses.findIndex((status) =>
    status.values.includes(applicationStatus.value)
  );
  const stepStatus = `${stepIndex + 1} / ${statuses.length}`;

  const isRejected = applicationStatus.value === StatusValue.Rejected;
  const isApproved = applicationStatus.value === StatusValue.Approved;
  const isCancelled = applicationStatus.value === StatusValue.Cancelled;

  return {
    currentStatus,
    stepStatus,
    isRejected,
    isApproved,
    isCancelled,
  };
};

export const getApplicationProgressConfig = (
  type: StatusType,
  statusArg: RawStatus
) => {
  const applicationStatusType: StatusType | undefined = type ?? statusArg?.type;
  const applicationType: ApplicationType =
    applicationStatusType === StatusType.Lmia
      ? ApplicationType.Lmia
      : ApplicationType.WorkPermit;
  const statuses =
    applicationStatusType === StatusType.Lmia
      ? STATUSES_GTS
      : STATUSES_WORK_PERMIT;
  const title =
    applicationStatusType === StatusType.Lmia
      ? 'LMIA Status'
      : 'Work Permit Status';

  return {
    applicationStatusType,
    applicationType,
    statuses,
    title,
  };
};

export const getApplicationStatusByStatus = (
  applicationStatus: RawStatus | null
) => {
  if (!applicationStatus) {
    return null;
  }

  if (applicationStatus.value === StatusValue.Cancelled) {
    return 'Cancelled';
  }

  return _.chain(STATUSES_WORK_PERMIT)
    .filter((status) => status.values.includes(applicationStatus?.value))
    .head()
    .value().label;
};

export const getMostRecentFeedback = (
  feedbacks: (Feedback | null)[] | null | undefined,
  status?: FeedbackStatus
) => {
  if (!feedbacks) return null;

  let feedback;

  if (status) {
    [feedback] = _.chain(feedbacks)
      .filter((feedback) => feedback?.status === status)
      .orderBy((feedback) => feedback?.id, 'desc')
      .value();
  } else {
    [feedback] = _.chain(feedbacks)
      .orderBy((feedback) => feedback?.id, 'desc')
      .value();
  }

  return feedback;
};

export const redirectUser = (
  pathname: string,
  shallow = true,
  query?: Record<string, any>
) => {
  router.push(
    {
      pathname,
      query,
    },
    undefined,
    { shallow }
  );
};

export const formatUserDisplayName = (
  user: UserRaw | RawPerson | null | undefined
) => {
  if (!user) return '';

  return trim(`${user.firstName || ''} ${user.lastName || ''}`);
};

export const oddOrEven = (x: number) => {
  return x & 1 ? 'odd' : 'even';
};

export const getParamsFromHashUrl = () => {
  const hash = window.location.hash.replace('#', '');
  const urlSearchParams = new URLSearchParams(hash);

  return Object.fromEntries(urlSearchParams.entries());
};

export const checkPasswordStrength = (password: string) => {
  // At least one lowercase letter (?=.*[a-z]), one uppercase letter (?=.*[A-Z]), one digit (?=.*[0-9]),
  // one special character (?=.*[^A-Za-z0-9]), and is at least eight characters long(?=.{8,}).
  const regex = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  );

  return regex.test(password);
};

export const checkIfPasswordsMatch = (
  password: string,
  passwordConfirm: string
) => {
  return password === passwordConfirm;
};

/*******************************
 * REPEATER FORM INPUT HELPERS *
 *******************************/
export const handleRepeaterFormErrors = (
  formManager: FormManager<any>,
  state: any,
  label: string,
  message: string
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  formManager.errors = formManager.errors.filter(({ path }) => path !== label);

  if (isEmpty(state)) return null;

  const errors: boolean[] = [];

  state.map((group: any) => {
    Object.keys(group).some((key) => {
      if (!isEmpty(errors)) return;

      const value = group[key as keyof any];

      if (isEmpty(errors) && (!value || value.length === 0)) errors.push(true);
    });
  });

  if (!isEmpty(errors)) {
    formManager.addError(message, label);
  } else {
    formManager.errors = formManager.errors.filter(
      ({ path }) => path !== label
    );
  }
};

export const handleRepeaterFormDeleteState = (
  groupId: number,
  state: any,
  setState: any
) => {
  const item = state.filter((_: any, i: number) => {
    return i !== groupId;
  });

  setState(item);
};

export const parseJSON = (jsonString: string) => {
  try {
    const json = JSON.parse(jsonString);

    if (json && typeof json === 'object') {
      return json;
    }

    return [];
  } catch (e) {
    return false;
  }
};

export const handleUploadErrorOnSomeFile = (error: any) => {
  if (error.includes('virus found:')) {
    toast.error(`Some file did not pass security check, please try again.`, {
      className: 'break-all',
    });
  } else if (error.includes('File truncated as it exceeds the')) {
    toast.error(
      `Some file the size is too large. Please make sure the file size is less than ${fileSizeLimitString}.`,
      {
        className: 'break-all',
      }
    );
  } else if (error.includes('mimetype not allowed')) {
    toast.error(`The file type not allowed.`, {
      className: 'break-all',
    });
  } else {
    toast.error(`Some file upload failed, something unexpected occurred.`, {
      className: 'break-all',
    });
  }
};

export const handleUploadError = (filename: string, error: any) => {
  if (error.includes('virus found:')) {
    toast.error(
      `File ${filename} did not pass security check, please try again.`,
      {
        className: 'break-all',
      }
    );
  } else if (error.includes('File truncated as it exceeds the')) {
    toast.error(
      `The ${filename} size is too large. Please make sure the file size is less than ${fileSizeLimitString}.`,
      {
        className: 'break-all',
      }
    );
  } else if (error.includes('mimetype not allowed')) {
    toast.error(`The file type not allowed.`, {
      className: 'break-all',
    });
  } else {
    toast.error(
      `File ${filename} upload failed, something unexpected occurred.`,
      {
        className: 'break-all',
      }
    );
  }
};

export const handleFilesRejected = (
  files: FileRejection[],
  maxFileSize?: string
) => {
  let hasError = false;

  files.forEach((fileRejected: FileRejection) => {
    fileRejected.errors.forEach((err) => {
      if (err.code === 'file-too-large') {
        toast.error(
          `File is too large. The max file size is ${
            maxFileSize ? maxFileSize : fileSizeLimitString
          }.`
        );
        hasError = true;
      } else {
        toast.error(`${fileRejected.file.name} ${err.message}`, {
          className: 'break-all',
        });
        hasError = true;
      }
    });
  });

  return hasError;
};

export const parseErrors = (e: any) => {
  if (e.response.errors) {
    return e.response.errors.map((e: any) => e.message);
  } else {
    logger.warn('No error and status code incorrect', e.response.status);
    return [];
  }
};

export const formatStringUrl = (url: string) => {
  let newUrl;

  try {
    newUrl = new URL(url);
  } catch (_) {
    return `https://${url}`;
  }

  return newUrl.protocol === 'http:'
    ? newUrl.href.replace('http', 'https')
    : newUrl.href;
};

export const getBrowserInfo = () => {
  const browser = detect();

  return {
    info: browser,
    is: {
      safari: browser?.name === 'safari' ?? false,
    },
  };
};

export const displayAccountUserFirstName = (account: AccountRaw) => {
  const user = lodash.head(account.users);

  return `${user?.firstName || ''}`;
};

export const displayAccountUser = (account: AccountRaw) => {
  const user = lodash.head(account.users);

  if (user) {
    return formatUserDisplayName(user) || `ID: ${account.id} no name user`;
  } else {
    return `ID: ${account.id} (no user)`;
  }
};

export const displayAccount = (account: AccountRaw) => {
  if (
    ['COMPANY', 'LAWYER', 'SUPER_ADMIN'].includes(
      account?.accountType?.name as string
    )
  ) {
    return account.name || `ID: ${account.id} (no account name).`;
  }

  return displayAccountUser(account);
};

export const getAllUsersFromAccounts = (
  accounts: AccountRaw[]
): UserRaw[] | [] => {
  return lodash
    .chain(accounts)
    .map((a) => a?.users)
    .flatten()
    .compact()
    .value();
};

export const createUrl = (domain: string) => {
  if (
    domain.trim().startsWith('http://') ||
    domain.trim().startsWith('https://')
  ) {
    return domain;
  }

  return `https://${domain}`;
};

export const formatPrice = (amount: number, hasDecimals?: boolean) => {
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: 2,
  }).format(hasDecimals ? amount / 100 : amount);
};

export const getErrorMessage = (e: any) => {
  return (
    (e as any)?.response?.errors?.[0]?.message || 'Sorry, something went wrong'
  );
};

export const stripHtml = (content: string) => {
  return content?.replace(/<[^>]*>?/gm, '');
};

export const stripEmptyHtmlTags = (content?: string | null) => {
  if (!content) {
    return '';
  }

  return content?.replace(/<[\S]+><\/[\S]+>/gim, '');
};

export const decodeHtml = (input: any) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');

  return doc.documentElement.textContent;
};

export const getInstructions = (
  meta: Record<any, any>,
  type: ApplicationType,
  country: string,
  path?: string,
  returnType: 'OBJECT' | 'HTML' = 'HTML'
) => {
  const countriesDetails = get(path ? meta[path] : meta, type) as any[];

  if (!countriesDetails || !country) {
    return null;
  }

  const countryDetail = head(
    countriesDetails.filter((details) => details.country === country)
  );

  if (isEmpty(countryDetail) || isEmpty(countryDetail?.text)) {
    return null;
  }

  if (returnType === 'HTML') {
    return decodeHtml(countryDetail?.text);
  }

  return {
    ...countryDetail,
    text: decodeHtml(countryDetail?.text),
  };
};

export const getAnswerFromFormSubmission = (
  formSubmission: Partial<FormSubmission> | null,
  questionLabel: string
) => {
  return head(
    formSubmission?.answers?.filter(
      (answer) => answer.question?.label === questionLabel
    )
  )?.answer;
};

export const getRolesAndTalentFromApplication = (application: Application) => {
  return _.chain(application.talent)
    .groupBy((app) => app?.role?.id)
    .map((talents: CompanyTalent[]) => {
      const [talent] = talents;
      return {
        role: talent.role,
        talents,
      };
    })
    .value() as unknown as GetRolesAndTalentFromApplicationType[];
};

export const removeQueryParam = (params: string[], router: NextRouter) => {
  const { pathname, query } = router;

  const urlParams = new URLSearchParams(query as any);

  params.forEach((paramString) => {
    urlParams.delete(paramString);
  });

  router.replace({ pathname, query: urlParams.toString() }, undefined, {
    shallow: true,
  });
};

export const scrollTo = (querySelector: string) => {
  return (document as Document).querySelector(querySelector)?.scrollIntoView({
    behavior: 'smooth',
  });
};

export function removeSuffix(name: string) {
  return name.replace(/-\d+$/gi, '');
}

export const bytesToGB = (bytes: number) => {
  return bytes / 1073741824; // 1 GB = 1,073,741,824 bytes
};

export const centsToDollars = (cents: number | null | undefined) => {
  if (!cents) {
    return 0;
  }

  const dollars = cents / 100;

  const roundedDollars = Math.round(dollars * 100) / 100;

  return roundedDollars.toFixed(2);
};

export const dollarsToCents = (input: number | string) => {
  let value = input;

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return Math.round(value * 100);
};

export const limitString = (str: string, maxLength = 75) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength - 3) + '...';
};

export const handleWindowSizeChangeForBuilderPage = () => {
  const mainNav = document.getElementById('mainNav')?.offsetHeight ?? 0;
  const notificationBar =
    document.getElementById('navNotificationBar')?.offsetHeight ?? 0;
  const header = document.getElementById('builderHeader')?.offsetHeight ?? 0;

  const elementsHeight = mainNav + notificationBar + header;

  const formBuilderContent = document.getElementById('builderContent');
  const formBuilderSidebar = document.getElementById('builderSidebar');
  const builderSidebarFamily = document.getElementById('builderSidebarFamily');

  if (formBuilderContent) {
    formBuilderContent.style.height = `calc(100vh - ${elementsHeight}px)`;
  }

  if (formBuilderSidebar) {
    formBuilderSidebar.style.height = `calc(100vh - ${elementsHeight}px)`;
  }

  if (builderSidebarFamily) {
    builderSidebarFamily.style.height = `calc(100vh - ${elementsHeight}px)`;
  }
};

export const parseJSONOrReturnString = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

export const getTimeAgo = (date: Date) => {
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
  }).replace('about ', '');
};

export const safeParseJson = (text: string | undefined | object) => {
  if (!text) return;
  try {
    if (typeof text === 'object') {
      return text;
    }
    return JSON.parse(text || '');
  } catch (e) {
    return;
  }
};

export const generateRandomNumber = (numDigits: number) => {
  const limit = Math.pow(10, numDigits) - 1;
  const id = Math.floor(Math.random() * (limit + 1));

  return id.toString().padStart(numDigits, '0');
};

export const isHtml = (str: string | null | undefined): boolean => {
  if (!str) return false;
  const htmlRegex = /<[^>]*>/;
  return htmlRegex.test(str);
};
