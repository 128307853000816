/* eslint-disable sonarjs/no-duplicate-string */
import User from 'dataLayer/User';
import {
  AccountSubscriptionData,
  PermissionOperation,
} from 'generated/graphql';
import toast from 'react-hot-toast';
import { PermissionGroup as PG } from 'static/Permissions.static';
import { supabase } from 'utils/supabase';

import {
  NAVIGATION_ITEM_TYPES,
  NavigationItemPropsWithIntrinsicAttributes,
} from './elements/NavigationItem/NavigationItem';

const superAdminNavigation: NavigationItemPropsWithIntrinsicAttributes[] = [
  {
    name: 'Home',
    href: '/admin',
    icon: 'Home',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['SUPER_ADMIN'],
  },
  {
    name: 'Manage Accounts',
    icon: 'User',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['SUPER_ADMIN'],
    children: [
      {
        name: 'Accounts',
        href: '/admin?page=accounts',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
      {
        name: 'Invitations',
        href: '/admin?page=invitations',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
    ],
  },
  {
    name: 'Applications',
    icon: 'Clipboard',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['SUPER_ADMIN'],
    children: [
      {
        name: 'List',
        href: '/admin?page=applications-overview',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
      {
        name: 'Board',
        href: '/admin?page=applications-board',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
    ],
  },
  {
    name: 'Builder',
    icon: 'Tool',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['SUPER_ADMIN'],
    children: [
      {
        name: 'Forms',
        href: '/builder/forms',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
      {
        name: 'Questions',
        href: '/admin?page=questions',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
    ],
  },
  {
    name: 'Other',
    icon: 'Zap',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['SUPER_ADMIN'],
    children: [
      {
        name: 'Permissions Overview',
        href: '/admin?page=permissions-overview',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
      {
        name: 'Account Updates',
        href: '/admin?page=account-updates',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['SUPER_ADMIN'],
      },
    ],
  },
];

const copilotUserNavigation: NavigationItemPropsWithIntrinsicAttributes[] = [
  {
    name: 'Copilot AI',
    image: 'BOT',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['COPILOT'],
    isExpandedOnLoad: true,
    children: [
      {
        name: 'Chat',
        href: '/',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['COPILOT'],
      },
      {
        name: 'Draft Documents',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        href: '/copilot/draft',
        allowedRoles: ['COPILOT'],
      },
    ],
  },
  {
    name: 'Clients',
    href: '/clients',
    icon: 'User',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['COPILOT'],
    showIf: async (user?: User | null) => {
      return user?.account?.subscription?.access?.drafting?.allowed === true;
    },
  },
  {
    name: 'Representatives',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    href: '/representatives',
    icon: 'Clipboard',
    allowedRoles: ['COPILOT'],
    showIf: async (user?: User | null) => {
      return user?.account?.subscription?.access?.drafting?.allowed === true;
    },
  },
];

export const navigation: NavigationItemPropsWithIntrinsicAttributes[] = [
  {
    name: 'Home',
    href: '/',
    icon: 'Home',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['TALENT', 'COMPANY', 'LAWYER', 'APPLICANT', 'SALES'],
  },
  {
    name: 'Copilot AI',
    image: 'BOT',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['LAWYER'],
    showIf: async (
      user?: User | null,
      subscription?: AccountSubscriptionData | null
    ) => {
      if (!user || !subscription) return false;

      return subscription?.isActive ? true : false;
    },
    children: [
      {
        name: 'Chat',
        href: '/copilot/chat',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['LAWYER'],
      },
      {
        name: 'Draft Documents',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        href: '/copilot/draft',
        allowedRoles: ['LAWYER'],
      },
      {
        name: 'Extract',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        href: '/copilot/extract',
        allowedRoles: ['LAWYER'],
        label: 'Beta',
        additionalClassNames: 'justify-between',
      },
    ],
  },
  {
    name: 'Tasks',
    href: '/tasks',
    icon: 'List',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['LAWYER'],
  },
  {
    name: 'Clients',
    href: '/clients',
    icon: 'User',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['LAWYER'],
    requiredPermissionGroups: [PG.LAW_IMM, PG.LAW_AGENT],
  },
  {
    name: 'Applications',
    icon: 'Clipboard',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['LAWYER'],
    requiredPermissions: [
      { resource: 'application-owner', operation: PermissionOperation.Read },
    ],
    children: [
      {
        name: 'List',
        href: '/applications/overview',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['LAWYER'],
        requiredPermissions: [
          {
            resource: 'application-owner',
            operation: PermissionOperation.Read,
          },
        ],
      },
      {
        name: 'Board',
        href: '/applications/board',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['LAWYER'],
        requiredPermissions: [
          {
            resource: 'application-owner',
            operation: PermissionOperation.Read,
          },
        ],
      },
      {
        name: 'Representatives',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        href: '/representatives',
        allowedRoles: ['LAWYER'],
      },
    ],
  },
  {
    name: 'Applications',
    icon: 'Clipboard',
    href: '/applications/overview',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['COMPANY'],
    requiredPermissions: [
      { resource: 'application-owner', operation: PermissionOperation.Read },
    ],
  },
  {
    name: 'Applications',
    icon: 'Clipboard',
    href: '/applications/overview',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['APPLICANT'],
    showIf: async (_, __, applications) => {
      return applications?.length && applications?.length > 1 ? true : false;
    },
    requiredPermissions: [
      { resource: 'application-owner', operation: PermissionOperation.Read },
    ],
  },
  {
    name: 'Talent',
    icon: 'User',
    href: '/talent/manage',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['COMPANY'],
    requiredPermissions: [
      { resource: 'company-talent-owner', operation: PermissionOperation.All },
    ],
  },
  {
    name: 'Roles',
    href: '/roles',
    icon: 'Paperclip',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['COMPANY'],
    requiredPermissions: [
      { resource: 'role-owner', operation: PermissionOperation.All },
    ],
  },
  {
    name: 'Leads',
    href: '/leads',
    icon: 'UserPlus',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['LAWYER'],
  },
  {
    name: 'Messenger',
    href: '/messenger',
    icon: 'Send',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    allowedRoles: ['COMPANY', 'TALENT', 'LAWYER', 'APPLICANT'],
    requiredPermissions: [
      { resource: 'conversation-owner', operation: PermissionOperation.All },
      { resource: 'conversation-owner', operation: PermissionOperation.Read },
    ],
  },
  {
    name: 'Builder',
    icon: 'Tool',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    allowedRoles: ['LAWYER'],
    requiredUserPermission: 'builder-all',
    children: [
      {
        name: 'Applications',
        href: '/builder/applications',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['LAWYER'],
      },
      {
        name: 'Forms',
        href: '/builder/forms',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['LAWYER'],
      },
      {
        name: 'Tasks',
        href: '/builder/tasks',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['LAWYER'],
      },
      {
        name: 'Documents',
        href: '/builder/documents',
        type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
        allowedRoles: ['LAWYER'],
      },
    ],
  },
  ...copilotUserNavigation,
  ...superAdminNavigation,
];

export const supportNavigation: NavigationItemPropsWithIntrinsicAttributes[] = [
  {
    name: 'Help Center',
    href: 'https://help.visto.ai/en/',
    icon: 'HelpCircle',
    type: NAVIGATION_ITEM_TYPES.EXTERNAL_LINK,
    allowedRoles: ['LAWYER'],
  },
];

export const userNavigation: Omit<
  NavigationItemPropsWithIntrinsicAttributes,
  'icon' | 'current'
>[] = [
  {
    name: 'Settings',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    href: '/account/settings',
    allowedRoles: [
      'TALENT',
      'COMPANY',
      'LAWYER',
      'SUPER_ADMIN',
      'APPLICANT',
      'COPILOT',
    ],
  },
  {
    name: 'Billing',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    href: '/account/billing',
    allowedRoles: ['LAWYER', 'COPILOT'],
    requiredPermissions: [
      { resource: 'billing-professionals', operation: PermissionOperation.All },
      { resource: 'billing-copilot', operation: PermissionOperation.All },
    ],
  },
  {
    name: 'Users',
    type: NAVIGATION_ITEM_TYPES.INTERNAL_LINK,
    href: '/account/users',
    allowedRoles: ['LAWYER', 'COMPANY', 'APPLICANT', 'COPILOT'],
    requiredPermissions: [
      { resource: 'manage-account-users', operation: PermissionOperation.All },
    ],
  },
  {
    name: 'Sign out',
    type: NAVIGATION_ITEM_TYPES.NON_LINK_ACTION,
    onClick: async () => {
      toast.loading('Signing out');

      if (typeof $crisp !== 'undefined') {
        $crisp.push(['do', 'session:reset']);
      }

      await supabase.auth.signOut();
    },
    allowedRoles: [
      'TALENT',
      'COMPANY',
      'LAWYER',
      'SUPER_ADMIN',
      'APPLICANT',
      'SALES',
      'COPILOT',
    ],
  },
];
